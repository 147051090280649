import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "C:/Users/felix/OneDrive/Documents/Bertram.Solutions/Website.TTcom/GatsbyJS-2022/src/components/mdx/component.js";
import { PortfolioPage, Summary, Review, Aside, Notes } from "../../../components/mdx/portfolio.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = PortfolioPage;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <h1>{props.pageContext.frontmatter.author} {props.pageContext.frontmatter.title}</h1>
    <Summary mdxType="Summary">
      <Aside mdxType="Aside">
        <p><strong parentName="p">{` Similar Strategies `}</strong></p>
        <ul>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/portfolios/tt-all-stars-xl/"
            }}>{`All-Stars XL`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/portfolios/tt-stocks-on-a-stroll/"
            }}>{`Stocks on a Stroll`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/portfolios/tt-rain-or-shine/"
            }}>{`Rain or Shine`}</a></li>
        </ul>
      </Aside>
      <ul>
        <li parentName="ul">{`Objective: fixed income`}</li>
        <li parentName="ul">{`Type: trend-following strategy`}</li>
        <li parentName="ul">{`Invests in: ETFs tracking U.S. Treasuries, commercial bonds`}</li>
        <li parentName="ul">{`Rebalancing schedule: weekly`}</li>
        <li parentName="ul">{`Taxation: 100% short-term capital gains`}</li>
        <li parentName="ul">{`Minimum account size: $1,500`}</li>
      </ul>
      <p>{`TuringTrader’s `}<em parentName="p">{`Buoy`}</em>{` aims to continually outperform the aggregate bond market and minimize interest-rate risk. The proprietary strategy trades bonds with a wide range of maturities and credit ratings as an expansion of Heine’s `}<a parentName="p" {...{
          "href": "/portfolios/heine-bond-model/"
        }}>{`Bond Trading Model`}</a>{`, which improves trend-following with macroeconomic cues. While the strategy calls for a weekly rebalancing schedule, most weeks will not require adjustments to its holdings, resulting in minimal maintenance requirements. Its high returns and low drawdowns make `}<em parentName="p">{`Buoy`}</em>{` an ideal drop-in replacement for bonds in a wide range of portfolios.`}</p>
    </Summary>
    <Review mdxType="Review">
      <h2>{`Strategy Rules`}</h2>
      <Aside mdxType="Aside">
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "512px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/2b23f891a5d398e021f35a8c1073fc00/01e7c/logo-512x512.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "100%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAE3klEQVQ4y2VUWWyUVRS+0iclvhgSeSAQjFAopWWVJeDGYgqiDxIMZakiRkz0wQVZhAiVLcGWgAoFElpppzNTpmWGLpS2086/3HPvv8zSzkwLEqG+woviE/2n95jzUwyJD+ffcu73f+d83zlMSsEcR7J79zKMc14kBDBNM5kQsBQAjgJAFAAMANABIAIA+6WEEsPglMMAoIjyEZFZlmA+WDptM9Pkkzj3k8oBoBsAkIJzjob5vygYJg/ejJszXFswW0LR72nB6upijI2ODj4L9jEAjBMIACjOuScEeBlXFCbCmwikGErKv1KOqEjagrk2FOm6yfwynwHDCbAxzrkSAlQ8Yarz4X51IdyvfgnF1c/BOJ5rjqufGvvGapv6sC9hYsoR69Ku8MunCwMB5ZzzcZNzNDkv0F03uEo7Qp1q6EX25m/I1jYi29CEbGNATXq/Wb28LYxLvmzzot06WoI/7B0wpgIQQ8NkAqDbloCOBWOO5d8VvQ8lJbZ0arh2XxQ/r+3CVz+5huv2RTGeMLF3wPRzpIAx27ZRCFEnpWTMkrCUGLX36Cp6S1fRbl1d79aR/hzp0rD1poaWAPxz2MZFX7Tilup2/CNn+a2htnAAFQyGUNO0R0NDmWlsJGMdbYwlkFU0eWxDk3ruvQAFsk0BfHFLEKduD2N924AvwpTKMG4+0o75tMSEZqDruiocbsGy8iWFUCiMd+6MVLGMK6LhDg2nf9TirfkuqmbuuobzPovg+VA/NsUSVJrPkMp8YXMQP/zxCaCmmwSIuz/do6bPmOXV1JzBfD53jkQxBQAdKtzNWqriYAzX7Y/h/byN1CNS3rUAW7s0ZO8GsPJYB2aTAi3bwYaGqzi3ZIGaXVzqHTr0A+Zy2RABGtQP0+SFpC1U6Z4Ivr036peoG0+MnHYEXmkdQLa+EXee7MRcSiAXEmOxG3j8xCm1bPlq78DBw5jP55sJMDLRYI/UJTuQkqTw08mg5xP1vchWNvgMn/YwlUqqnp5eNb9ssVdTewZHRoZrCHA/AUoBHvXp+Q+C6p0DMRxMiv8AiSGZ+KWtITxw4ZbPPqHpmMlk1Fdf78XZxaWFtrbrePv2yBYmJcwjM5P/Il2aYpsCauP3BChRN0w0DANN00Tg3BeCnunb0NCgqq9vUHNLyse3Vu5A27Yf6HpiCqOtYZg8RCwutPSPsTVXccPBmMo4gNKyMJ1Oo+s6aNsWOo6NyWQSU6mUunjxklq+4nUsnlP2uKkpgNns0MnR0fvMX0GdcWPGYFL8/Wuon8bL2368HW9nU+rS5Svqm2/3IXmso6MTYzfa1cVLl9Xu3XtU+YKlOPOVOWPV1ccxnU7dbQ4GJgvBmT/QlgSamArqV3uPjvGEMZZ0XbWzahcdUouXrFArVr6hXlu2SpXOX0TfxktKFz4+Wn0MHcf5xzSN8mTSJXKTmKYZNMtFUvig620JDy0pMd7fj6tWv1Ugj82ZW+bNKi71ZhfP9xYuWlao3FaFjY0BnxmBpdMp5jhOkZQW8zdtWnImBRTZElhfwpzKAeoA+KOWlmt4+nQNkmkPHz6CtWfOYiTSipZlPchlsyebmwOTiRmBua7r68FsW7CzZ68zWo5UPvWBc2C2LaeNjAxXDefz52gCcrlscHg4X0PWIDVJAMqlMokZgdH2/xceF02SwJ+/igAAAABJRU5ErkJggg==')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "TuringTrader.com, Buoy: A sophisticated bond strategy",
                "title": "TuringTrader.com, Buoy: A sophisticated bond strategy",
                "src": "/static/2b23f891a5d398e021f35a8c1073fc00/01e7c/logo-512x512.png",
                "srcSet": ["/static/2b23f891a5d398e021f35a8c1073fc00/5a46d/logo-512x512.png 300w", "/static/2b23f891a5d398e021f35a8c1073fc00/01e7c/logo-512x512.png 512w"],
                "sizes": "(max-width: 512px) 100vw, 512px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <p><strong parentName="p">{` Learn More `}</strong></p>
        <ul>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/articles/bonds-rising-yields/"
            }}>{`Bonds and Rising Yields`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/portfolios/heine-bond-model/"
            }}>{`Heine's Bond Model`}</a></li>
        </ul>
      </Aside>
      <p>{`The operation of `}<em parentName="p">{`Buoy`}</em>{` can be summarized as follows:`}</p>
      <ul>
        <li parentName="ul">{`consider high-yield bonds, corporate bonds, and U.S. Treasuries with long-term, mid-term, and short-term maturities a universe with a known performance ranking`}</li>
        <li parentName="ul">{`use `}<em parentName="li">{`Heine's Bond Trading Model`}</em>{` to determine investability for each asset in the universe`}</li>
        <li parentName="ul">{`in addition, validate high-yield bonds with Market Vane`}</li>
        <li parentName="ul">{`allocate equal capital to the top two investable assets`}</li>
        <li parentName="ul">{`if no investable assets exist, invest in the fallback asset`}</li>
        <li parentName="ul">{`choose TIPS and T-bills as the fallback asset, based on their relative momentum`}</li>
      </ul>
      <h2>{`Diversification`}</h2>
      <p>{`TuringTrader's `}<em parentName="p">{`Buoy`}</em>{` strategy ultimately only invests in a single asset class: bonds. However, the strategy employs a level of `}<em parentName="p">{`serial diversification`}</em>{` by rotating through a universe of several ETFs representing various segments of the U.S. bond market.`}</p>
      <h2>{`Returns & Volatility`}</h2>
      <p>{`TuringTrader's `}<em parentName="p">{`Buoy`}</em>{` strategy provides a continuous upside over the aggregate bond market benchmark. The rolling returns and the tracking chart illustrate this upside. At the same time, the strategy has a risk level that is about on par with its benchmark. This statement is supported by the underwater chart and the Monte-Carlo simulation, even though the standard deviation suggests otherwise. Most importantly, the strategy limits interest rate risks, which is highly relevant in environments of rising rates, as seen in the periods from 2015 to 2018 or from 2021 onward.`}</p>
      <h2>{`Account & Tax Considerations`}</h2>
      <p>{`When the `}<em parentName="p">{`Buoy`}</em>{` strategy changes its holdings, it triggers taxable events. Investors should not assume that any assets are held long enough to qualify for long-term treatment of capital gains. However, because interest payments from bonds are taxed with the regular income tax rate, this has only a small effect on the strategy's overall tax burden. Like other bond strategies, `}<em parentName="p">{`Buoy`}</em>{` achieves its best results in tax-deferred accounts.`}</p>
      <p>{`Because the strategy only holds no more than two ETFs, accounts funded with $1,500 or more are sufficient to implement the strategy.`}</p>
    </Review>
    <Notes mdxType="Notes">
      <p><strong parentName="p">{`Portfolio Revisions`}</strong></p>
      <ul>
        <li parentName="ul"><a parentName="li" {...{
            "href": "./?v=1"
          }}>{`v1, February 2022`}</a>{`: Initial release, based on `}<a parentName="li" {...{
            "href": "/portfolios/heine-bond-model/"
          }}>{`Heine's Bond Model`}</a>{`.`}</li>
      </ul>
    </Notes>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      